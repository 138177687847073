@media (min-width: 1024px) {
  .functionList {
    /* border: 1px solid red; */
    width: 100%;
    height: 2350px;
  }
  .container {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
  }
  .mainBox {
    position: relative;
  }
  .eachBox {
    /* border: 1px solid red; */
    position: absolute;
    border-radius: 12px;
    background-color: var(--white);
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    margin-top: 50px;
    padding: 16px 5px;
  }
  .firstBoxLeft {
    display: flex;
    gap: 8px;
  }
  .firstBoxLeft > h1 {
    font-family: var(--primaryFont);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .firstBoxRight {
    display: flex;
  }
  /* Apply these styles to your list items */
  .custom_list {
    margin-top: 24px;
  }
  .custom_list li {
    /* border: 1px solid red; */
    position: relative;
    list-style-type: none;
    padding-left: 24px; /* To make space for the bullet point */
    margin-bottom: 10px; /* Adjust as needed */
    font-size: 16px;
    font-family: var(--primaryFont);
    color: var(--black);
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .custom_list li::before {
    content: ""; /* Empty content to add the bullet point */
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 24px;
    background-color: #e110f0;
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='24' fill='none'><circle opacity='0.1' cx='8' cy='12' r='8' fill='%23E110F0'/><rect x='5' y='12' width='4' height='4' transform='rotate(-45 5 12)' fill='%23E110F0'/></svg>");
    mask-size: cover;
  }

  .firstBox {
    top: 0;
    left: 0;
    width: 564px;
    height: 262px;
  }
  .secondBox {
    top: 50px;
    right: 0;
    width: 564px;
    height: 246px;
  }
  .thirdBox {
    top: 312px;
    left: 122px;
    width: 368px;
    height: 206px;
  }
  .fourthBox {
    top: 362px;
    right: 0;
    width: 564px;
    height: 262px;
  }
  .fifthBox {
    top: 568px;
    left: 122px;
    width: 270px;
    height: 290px;
  }
  .sixthBox {
    top: 674px;
    left: 710px;
    width: 368px;
    height: 222px;
  }
  .seventhBox {
    top: 908px;
    left: 0;
    width: 564px;
    height: 286px;
  }
  .eighthBox {
    top: 946px;
    left: 710px;
    width: 368px;
    height: 182px;
  }
  .ninethBox {
    top: 1244px;
    left: 122px;
    width: 368px;
    height: 176px;
  }
  .tenthBox {
    top: 1278px;
    left: 710px;
    width: 344px;
    height: 152px;
  }
  .eleventhBox {
    top: 1560px;
    left: 0;
    width: 564px;
    height: 294px;
  }
  .twelvethBox {
    top: 1600px;
    left: 710px;
    width: 270px;
    height: 222px;
  }
  .thirteenthBox {
    top: 1904px;
    left: 82px;
    width: 466px;
    height: 230px;
  }
  .fourteenthBox {
    top: 1942px;
    left: 710px;
    width: 368px;
    height: 262px;
  }
  /* .firstBox,
.secondBox,
.fourthBox,
.seventhBox > .firstBoxRight > .custom_list {
  width: 50%;
} */
  .firstBox .custom_list {
    width: 50%;
  }
  .secondBox .custom_list {
    width: 50%;
  }
  .fourthBox .custom_list {
    width: 50%;
  }
  .seventhBox .custom_list {
    width: 50%;
  }
  .eleventhBox .custom_list {
    width: 50%;
  }
  .thirteenthBox .custom_list {
    width: 50%;
  }
  .straightLine::after {
    height: 2350px;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 3px; /* Width of the vertical line */
    background: linear-gradient(to bottom, #356cfb 66%, #0ada5d 34%);
    transform: translateX(-50%);
  }
  .eachCircle {
    content: "";
    position: absolute;
    left: 50%;
    width: 15px; /* Diameter of the circle */
    height: 15px; /* Diameter of the circle */
    border-radius: 50%; /* Makes the element a circle */
    background-color: #356cfb; /* Color of the circle */
    transform: translate(-50%, -50%);
  }
  .eachConnection {
    content: "";
    position: absolute;
    background-color: #356cfb; /* Color of the circle */
    transform: translate(-50%, -50%);
  }
  .firstCircle {
    top: 80px;
  }
  .firstConnection {
    top: 80px;
    left: 48%;
    width: 50px; /* Length of the horizontal line */
    height: 3px; /* Thickness of the line */
  }
  .secondCircle {
    top: 130px;
  }
  .secondConnection {
    top: 130px;
    left: 52%;
    width: 50px; /* Length of the horizontal line */
    height: 3px;
  }
  .thirdCircle {
    top: 400px;
  }
  .thirdConnection {
    top: 400px;
    left: 45%;
    width: 110px; /* Length of the horizontal line */
    height: 3px;
  }
  .fourthCircle {
    top: 450px;
  }
  .fourthConnection {
    top: 450px;
    left: 52%;
    width: 50px; /* Length of the horizontal line */
    height: 3px;
  }
  .fifthCircle {
    top: 660px;
  }
  .fifthConnection {
    top: 660px;
    left: 41%;
    width: 205px; /* Length of the horizontal line */
    height: 3px;
  }
  .sixthCircle {
    top: 750px;
  }
  .sixthConnection {
    top: 750px;
    left: 55%;
    width: 110px; /* Length of the horizontal line */
    height: 3px;
  }
  .seventhCircle {
    top: 990px;
  }
  .seventhConnection {
    top: 990px;
    left: 48%;
    width: 50px; /* Length of the horizontal line */
    height: 3px;
  }
  .eighthCircle {
    top: 1020px;
  }
  .eighthConnection {
    top: 1020px;
    left: 55%;
    width: 110px; /* Length of the horizontal line */
    height: 3px;
  }
  .ninethCircle {
    top: 1320px;
  }
  .ninethConnection {
    top: 1320px;
    left: 45%;
    width: 110px; /* Length of the horizontal line */
    height: 3px;
  }
  .tenthCircle {
    top: 1360px;
  }
  .tenthConnection {
    top: 1360px;
    left: 55%;
    width: 110px; /* Length of the horizontal line */
    height: 3px;
  }
  .comingSoon {
    position: absolute;
    display: inline-flex;
    padding: 4px 18px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 34px;
    background-color: #0ada5d;
    color: #fff;
    font-family: var(--primaryFont);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    z-index: 1;
    color: var(--white);
    top: 1530px;
    left: 44.5%;
  }
  .eleventhCircle {
    top: 1640px;
    background-color: #0ada5d;
  }
  .eleventhConnection {
    top: 1640px;
    left: 48%;
    background-color: #0ada5d;
    width: 50px; /* Length of the horizontal line */
    height: 3px;
  }
  .twelvethCircle {
    top: 1680px;
    background-color: #0ada5d;
  }
  .twelvethConnection {
    top: 1680px;
    left: 55%;
    background-color: #0ada5d;
    width: 120px; /* Length of the horizontal line */
    height: 3px;
  }
  .thirteenthCircle {
    top: 1980px;
    background-color: #0ada5d;
  }
  .thirteenthConnection {
    top: 1980px;
    left: 48%;
    background-color: #0ada5d;
    width: 60px; /* Length of the horizontal line */
    height: 3px;
  }
  .fourteenthCircle {
    top: 2030px;
    background-color: #0ada5d;
  }
  .fourteenthConnection {
    top: 2030px;
    left: 55%;
    background-color: #0ada5d;
    width: 120px; /* Length of the horizontal line */
    height: 3px;
  }
}
@media (min-width: 300px) and (max-width: 1023px) {
  .functionList {
    /* border: 1px solid red; */
    width: 100%;
    height: max-content;
  }
  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .mainBox {
    position: relative;
  }
  .eachBox {
    /* position: absolute; */
    border-radius: 12px;
    background-color: var(--white);
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    margin-top: 50px;
    width: 80%;
    padding: 16px;
    margin-left: 50px;
  }
  .eachBox > div:nth-child(1) {
    display: flex;
    gap: 10px;
  }
  .firstBoxLeft > h1 {
    font-family: var(--primaryFont);
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
  }
  .custom_list {
    margin-top: 24px;
  }
  .custom_list li {
    /* border: 1px solid red; */
    position: relative;
    list-style-type: none;
    margin-bottom: 10px; /* Adjust as needed */
    font-size: 16px;
    font-family: var(--primaryFont);
    color: var(--black);
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .custom_list li::before {
    content: ""; /* Empty content to add the bullet point */
    position: absolute;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
    width: 16px;
    height: 24px;
    background-color: #e110f0;
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='24' fill='none'><circle opacity='0.1' cx='8' cy='12' r='8' fill='%23E110F0'/><rect x='5' y='12' width='4' height='4' transform='rotate(-45 5 12)' fill='%23E110F0'/></svg>");
    mask-size: cover;
  }
  .straightLine::after {
    height: 100%;
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 5%;
    width: 3px; /* Width of the vertical line */
    background: linear-gradient(to bottom, #356cfb 71.7%, #0ada5d 29%);
    transform: translateX(-50%);
  }
  .comingSoon {
    position: absolute;
    display: inline-flex;
    padding: 4px 18px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 34px;
    background-color: #0ada5d;
    color: #fff;
    font-family: var(--primaryFont);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    z-index: 1;
    color: var(--white);
    top: 71%;
    left: 1%;
  }
  .eachCircle {
    content: "";
    position: absolute;
    left: 5%;
    width: 15px; /* Diameter of the circle */
    height: 15px; /* Diameter of the circle */
    border-radius: 50%; /* Makes the element a circle */
    background-color: #356cfb; /* Color of the circle */
    transform: translate(-50%, -50%);
  }
  .eachConnection {
    content: "";
    position: absolute;
    background-color: #356cfb; /* Color of the circle */
    transform: translate(-50%, -50%);
    left: 9%;
    width: 32px;
    height: 3px;
  }
  .firstCircle {
    top: 0.65%;
  }
  .firstConnection {
    top: 0.65%;
  }
  .secondCircle {
    top: 10.7%;
  }
  .secondConnection {
    top: 10.7%;
  }
  .thirdCircle {
    top: 20.35%;
  }
  .thirdConnection {
    top: 20.35%;
  }
  .fourthCircle {
    top: 25.5%;
  }
  .fourthConnection {
    top: 25.5%;
  }
  .fifthCircle {
    top: 34.8%;
  }
  .fifthConnection {
    top: 34.8%;
  }
  .sixthCircle {
    top: 41.1%;
  }
  .sixthConnection {
    top: 41.1%;
  }
  .seventhCircle {
    top: 47%;
  }
  .seventhConnection {
    top: 47%;
  }
  .eighthCircle {
    top: 57.5%;
  }
  .eighthConnection {
    top: 57.5%;
  }
  .ninethCircle {
    top: 63.2%;
  }
  .ninethConnection {
    top: 63.2%;
  }
  .tenthCircle {
    top: 68%;
  }
  .tenthConnection {
    top: 68%;
  }

  .eleventhCircle {
    top: 72.8%;
    background-color: #0ada5d;
  }
  .eleventhConnection {
    top: 72.8%;
    background-color: #0ada5d;
  }
  .twelvethCircle {
    top: 82.3%;
    background-color: #0ada5d;
  }
  .twelvethConnection {
    top: 82.3%;
    background-color: #0ada5d;
  }
  .thirteenthCircle {
    top: 88%;
    background-color: #0ada5d;
  }
  .thirteenthConnection {
    top: 88%;
    background-color: #0ada5d;
  }
  .fourteenthCircle {
    top: 95.5%;
    background-color: #0ada5d;
  }
  .fourteenthConnection {
    top: 95.5%;
    background-color: #0ada5d;
  }
}
