@media (min-width: 1024px) {
  .embrace {
    /* border: 1px solid red; */
    width: 100%;
    height: 296px;
  }
  .container {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
    background: linear-gradient(to right, #fe0dfd, #4f18a9);
    border-radius: 48px;
    display: flex;
    justify-content: flex-start;
    gap: 32px;
    align-items: center;
    padding: 24px;
  }
  .zimbleNimble {
    border-radius: 48px;
  }
  .embraceContent {
    text-align: left;
    color: var(--white);
  }
  .embraceContent > h1 {
    font-size: 40px;
    font-family: var(--primaryFont);
    font-weight: 700;
    line-height: normal;
  }
  .embraceContent > p {
    font-size: 22px;
    font-family: var(--primaryFont);
    font-weight: 400;
    line-height: 28px;
  }
  .embraceContentButton {
    /* border: 1px solid red; */
    margin-top: 16px;
    width: 200px;
    cursor: pointer;
    height: 40px;
    border-radius: 100px;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #356cfb;
    color: var(--white);
    font-weight: 700;
    font-size: 18px;
    text-decoration: none;
  }
  .backImage {
    height: 100%;
    width: 50%;
    display: flex;
    margin-left: -450px;
    /* border: 1px solid red; */
    position: relative;
  }
  .eachBackImage {
    /* border: 1px solid black; */
    position: absolute;
  }
  .backImage > .eachBackImage:nth-child(1) {
    width: 100%;
    top: 80px;
    left: 70px;
    /* border: 1px solid red; */
  }
  .backImage > .eachBackImage:nth-child(2) {
    /* border: 1px solid red; */
    width: 90%;
    top: 70px;
    left: 220px;
  }
  .backImage > .eachBackImage:nth-child(3) {
    width: 50%;
    height: 100%;
    top: 90px;
    left: 370px;
    /* border: 1px solid red; */
  }
}
@media (min-width: 679px) and (max-width: 1023px){
  .embrace {
    /* border: 1px solid red; */
    width: 100%;
    height: max-content;
  }
  .container {
    /* border: 1px solid black; */
    width: 80%;
    height: 100%;
    background: linear-gradient(to right, #fe0dfd, #4f18a9);
    border-radius: 48px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    /* padding: 10px; */
    margin: auto;
    margin-top: 50px;
  }
  .zimbleNimble {
    padding: 50px;
    border-radius: 108px;
  }
  .embraceContent {
    text-align: left;
    color: var(--white);
  }
  .embraceContent > h1 {
    padding: 0 50px;
    font-size: 32px;
    font-family: var(--primaryFont);
    font-weight: 700;
    line-height: normal;
  }
  .embraceContent > p {
    padding: 0 50px;
    font-size: 22px;
    font-family: var(--primaryFont);
    font-weight: 400;
    line-height: 28px;
    /* margin-bottom: 10px; */
  }
  .embraceContentButton {
    margin-top: 50px;
    width: 80%;
    margin: auto;
    height: 50px;
    border-radius: 100px;
    padding: 16px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #356cfb;
    color: var(--white);
    min-width: 160px;
    font-weight: 700;
    font-size: 26px;
    z-index:1;
  }
  .backImage>div:nth-child(1){
    /* height: 100px; */
    margin-top: -250px;
    margin-left: -100px;
  }
  .backImage>div:nth-child(2){
    margin-top: -230px;
    margin-left: 100px;
    /* display: none; */
  }
  .backImage>div:nth-child(3){
    display: none;
  }
}

@media (min-width: 300px) and (max-width: 678px) {
  .embrace {
    /* border: 1px solid red; */
    width: 100%;
    height: max-content;
  }
  .container {
    /* border: 1px solid black; */
    width: 95%;
    height: 100%;
    background: linear-gradient(to right, #fe0dfd, #4f18a9);
    border-radius: 48px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    /* padding: 10px; */
    margin: auto;
    margin-top: 50px;
  }
  .zimbleNimble {
    padding: 10px;
    border-radius: 48px;
  }
  .embraceContent {
    text-align: left;
    color: var(--white);
  }
  .embraceContent > h1 {
    padding: 10px;
    font-size: 23px;
    font-family: var(--primaryFont);
    font-weight: 700;
    line-height: normal;
  }
  .embraceContent > p {
    padding: 0 10px;
    font-size: 18px;
    font-family: var(--primaryFont);
    font-weight: 400;
    line-height: 28px;
  }
  .embraceContentButton {
    margin-top: 16px;
    width: 80%;
    margin: auto;
    height: 40px;
    border-radius: 100px;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #356cfb;
    color: var(--white);
    min-width: 160px;
    font-weight: 700;
    font-size: 18px;
    z-index:1;
  }
  .backImage>div:nth-child(1){
    /* height: 100px; */
    margin-top: -280px;
    margin-left: -100px;
  }
  .backImage>div:nth-child(2){
    margin-top: -200px;
    margin-left: 100px;
    /* display: none; */
  }
  .backImage>div:nth-child(3){
    display: none;
  }
  /* .backImage {
    margin-top: -50px;
    height: 100%;
    width: 100%;
    display: flex;
    border: 1px solid red;
    position: relative;
  }
  .eachBackImage {
    border: 1px solid black;
    position: absolute;
  }
  .backImage > .eachBackImage:nth-child(1) {
    width: 100%;
    top: 80px;
    left: 200px;
    border: 1px solid red;
  }
  .backImage > .eachBackImage:nth-child(2) {
    border: 1px solid red;
    width: 90%;
    top: 70px;
    left: 350px;
  }
  .backImage > .eachBackImage:nth-child(3) {
    width: 50%;
    height: 100%;
    top: 90px;
    left: 500px;
    border: 1px solid red;
  } */
}
