@media (min-width: 1024px) {
  .superbox {
    /* border: 10px solid red; */
    width: 100%;
    height: 1081px;
  }

  .mainbox {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
  }
  .terms_condition_mainbox {
    display: flex;
  }
  .terms_condition_contentbox {
    width: 95%;
    /* height: 60%; */
    /* border: 1px solid red; */
  }
  .rectangle_56_1_img {
    margin-right: -13%;
  }
  .rectangle_57_1_img {
    margin-left: -13%;
    margin-top: 49%;
  }
  .terms_condition_second_contentbox {
    text-align: left;
    width: 80%;
    /* height: 600px; */
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    /* border: 1px solid red; */
  }
  .legal {
    text-align: center;
    color: #cc2ad7;
    font-size: 20px;
    font-weight: 600;
  }
  .Terms_Conditions {
    text-align: center;
    font-size: 35px;
    font-weight: 800;
  }
  .Terms_Conditions_first_content_box {
    margin-bottom: 10%;
    margin-top: 5%;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
    .superbox {
        /* border: 10px solid red; */
        width: 100%;
        height: max-content;
      }
    
      .mainbox {
        /* border: 1px solid black; */
        width: 100%;
        height: 100%;
        margin: auto;
      }
      .terms_condition_mainbox {
        display: flex;
      }
      .terms_condition_contentbox {
        width: 100%;
        /* height: 60%; */
        /* border: 1px solid red; */
    }
    .rectangle_56_1_img {
        display: none;
    }
    .rectangle_57_1_img {
        display: none;
    }
    .Terms_Conditions_first_content_box{
        /* border: 1px solid red; */
        width: 98%;
        font-size: 16px;
        font-family: var(--primaryFont);
        font-weight: 400;
        
      }
      .terms_condition_second_contentbox {
        text-align: left;
        width: 80%;
        /* height: 600px; */
        margin: auto;
        margin-top: 5%;
        margin-bottom: 5%;
        /* border: 1px solid red; */
      }
      .legal {
        text-align: center;
        color: #cc2ad7;
        font-size: 20px;
        font-weight: 600;
      }
      .Terms_Conditions {
        text-align: center;
        font-size: 35px;
        font-weight: 800;
      }
      .Terms_Conditions_first_content_box {
        margin-bottom: 10%;
        margin-top: 5%;
      }
}
