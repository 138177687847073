@media (min-width: 1024px) {
  .heroSection {
    /* border: 1px solid red; */
    width: 100%;
    height: 681px;
    background-image: url(../../../Assets/Images/platform_hero_image.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .container {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    position: relative;
    /* align-items: center; */
  }
  .containerLeft > h1 {
    /* border: 1px solid black; */
    width: 57%;
    font-family: var(--primaryFont);
    font-size: 62px;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 150px;
  }
  .containerLeft > p {
    width: 60%;
    margin-top: 20px;
    font-family: var(--primaryFont);
    font-size: 22px;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .letsConnect {
    /* border: 1px solid red; */
    text-align: left;
    width: 100%;
    color: #356cfb;
    background: none;
    font-family: var(--primaryFont);
    font-size: 20px;
    font-weight: 600;
    /* margin-top: 30px; */
  }
  .containerRight {
    top: -20px;
    position: absolute;
    right: -165px;
  }
}
@media (min-width: 679px) and (max-width: 1023px){
  .heroSection {
    /* border: 1px solid red; */
    width: 100%;
    height: 681px;
    background-color: #c7c7fb;
  }
  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .containerLeft {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .containerLeft > h1 {
    /* border: 1px solid red; */
    width: 60%;
    font-family: var(--primaryFont);
    margin-top: 50px;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
  }
  .containerLeft > p {
    /* border: 1px solid red; */
    width: 70%;
    font-family: var(--primaryFont);
    margin-top: 10px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
  }
  .letsConnect {
    /* border: 1px solid red; */
    text-align: center;
    width: 100%;
    color: #356cfb;
    background: none;
    font-family: var(--primaryFont);
    font-size: 24px;
    font-weight: 600;
  }
  .containerRight {
    top: -100px;
    position: absolute;
    top: 160px;
  }
}
@media (min-width: 300px) and (max-width: 678px) {
  .heroSection {
    /* border: 1px solid red; */
    width: 100%;
    height: 681px;
    background-color: #c7c7fb;
  }
  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .containerLeft {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .containerLeft > h1 {
    /* border: 1px solid red; */
    width: 95%;
    font-family: var(--primaryFont);
    margin-top: 50px;
    text-align: center;
    font-size: 33px;
    font-weight: 700;
    line-height: 45px;
  }
  .containerLeft > p {
    /* border: 1px solid red; */
    width: 93%;
    font-family: var(--primaryFont);
    margin-top: 10px;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
  }
  .letsConnect {
    /* border: 1px solid red; */
    text-align: center;
    width: 100%;
    color: #356cfb;
    background: none;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 600;
  }
}
