@media (min-width: 1024px) {
  .container {
    /* border: 1px solid red; */
    text-align: center;
    /* font-size: 40px; */
  }
}
@media (min-width: 300px) and (max-width: 678px) {
  .container {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 98%;
    margin: left;
    font-size: 23px;
    height: 40px;
    text-align: center;
    /* border: 1px solid red; */
  }
}
