@media (min-width: 1024px) {
  .comingsoon {
    /* border: 1px solid red; */
    width: 100%;
    height: 800px;
    margin-top: 50px;
  }
  .container {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
    justify-content: center;
  }
  .container > img {
    /* border: 1px solid red; */
    height: 700px;
    width: 700px;
    border-radius: 100px;
    box-shadow: 0 12px 44px rgba(69, 117, 183, 0.4);
  }
  .container > h1 {
    margin-top: -400px;
    /* border: 1px solid red; */
    font-size: 62px;
    font-weight: 800;
    font-family: var(--primaryFont);
  }
  .container > button {
    margin-top: 30px;
    /* border: 1px solid red; */
    background-color: #356cfb;
    height: 40px;
    width: 150px;
    color: white;
    border-radius: 30px;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {

  .comingsoon {
      /* border: 1px solid red; */
      width: 100%;
      height: 700px;
      margin-top: 50px;
    }
    .container {
      /* border: 1px solid black; */
      width: 100%;
      height: 100%;
      margin: auto;
      justify-content: center;
    }
    .container > img {
      /* border: 1px solid red; */
      height: 90%;
      width: 90%;
      border-radius: 30px;
      box-shadow: 0 12px 44px rgba(69, 117, 183, 0.4);
    }
    .container > h1 {
      margin-top: -380px;
      /* border: 1px solid red; */
      font-size: 32px;
      font-weight: 800;
      font-family: var(--primaryFont);
    }
    .container > button {
      margin-top: 10px;
      /* border: 1px solid red; */
      background-color: #356cfb;
      height: 60px;
      width: 200px;
      color: white;
      border-radius: 30px;
    }
}
@media (min-width: 320px) and (max-width: 768px) {
    .comingsoon {
        /* border: 1px solid red; */
        width: 100%;
        height: 300px;
        margin-top: 50px;
      }
      .container {
        /* border: 1px solid black; */
        width: 100%;
        height: 100%;
        margin: auto;
        justify-content: center;
      }
      .container > img {
        /* border: 1px solid red; */
        height: 90%;
        width: 90%;
        border-radius: 30px;
        box-shadow: 0 12px 44px rgba(69, 117, 183, 0.4);
      }
      .container > h1 {
        margin-top: -180px;
        /* border: 1px solid red; */
        font-size: 32px;
        font-weight: 800;
        font-family: var(--primaryFont);
      }
      .container > button {
        margin-top: 10px;
        /* border: 1px solid red; */
        background-color: #356cfb;
        height: 40px;
        width: 150px;
        color: white;
        border-radius: 30px;
      }
}