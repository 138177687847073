@media (min-width: 1024px) {
  .heroSection {
    /* border: 1px solid red; */
    width: 100%;
    height: 681px;
    background-image: url(../../../Assets/Images/function_hero_background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    fill: var(--grey-grey-03, #dadae9);
  }
  .container {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    position: relative;
    /* align-items: center; */
  }
  .containerLeft > h6 {
    font-family: var(--primaryFont);
    font-size: 16px;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 150px;
    color: #e110f0;
  }
  .containerLeft > h1 {
    width: 70%;
    font-family: var(--primaryFont);
    font-size: 62px;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .containerLeft > p {
    width: 65%;
    margin-top: 20px;
    font-family: var(--primaryFont);
    font-size: 22px;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .containerRight{
    display: none;
  }
}
@media (min-width: 679px) and (max-width: 1023px) {
  .heroSection {
    /* border: 1px solid red; */
    width: 100%;
    height: 681px;

  }
  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    background-color: #d8d9ff;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    /* align-items: center; */
  }
  .containerLeft {
    margin-top: 100px;
    /* border: 1px solid red; */

  }
  .containerLeft > h6 {
    font-family: var(--primaryFont);
    font-size: 25px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* margin-top: 150px; */
    color: #e110f0;
  }
  .containerLeft > h1 {
    width: 70%;
    margin: auto;
    font-family: var(--primaryFont);
    font-size: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .containerLeft > p {
    width: 70%;
    margin: auto;
    margin-top: 20px;
    font-family: var(--primaryFont);
    font-size: 25px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .containerRight{
    /* border: 1px solid red; */
    margin-top: -150px;
  }
}
@media (min-width: 300px) and (max-width: 678px) {
  .heroSection {
    /* border: 1px solid red; */
    width: 100%;
    height: 681px;

  }
  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    background-color: #d8d9ff;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    /* align-items: center; */
  }
  .containerLeft > h6 {
    font-family: var(--primaryFont);
    font-size: 16px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* margin-top: 150px; */
    color: #e110f0;
  }
  .containerLeft > h1 {
    width: 90%;
    margin: auto;
    font-family: var(--primaryFont);
    font-size: 36px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .containerLeft > p {
    width: 91%;
    margin: auto;
    margin-top: 20px;
    font-family: var(--primaryFont);
    font-size: 18px;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

}
