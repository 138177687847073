@media (min-width: 1024px) {
/* @media (min-width: 1024px) { */
  .heroSection {
    /* border: 1px solid red; */
    width: 100%;
    height: 630px;
    background: linear-gradient(to top, #c6c3ff 40%, #d7d4ff);
    display: flex;
    justify-content: center;
    position: relative; /* Set the position to relative */
  }
  
  .mainBox {
    height: 100%;
    width: 1200px;
    position: relative; /* Set the position to relative */
    /* z-index: 1; */
  }

  .backgroundVideo {
    /* border: 1px solid red; */
    position: absolute;
    margin-top: 50px;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* z-index: 0; */
  }
  
  .container {
    width: 65%;
    height: 100%;
    flex-direction: column;
    justify-content: left;
    position: relative; /* Set the position to relative */
    z-index: 2; /* Add a higher z-index to place it above the video */
  }
  .rightSide {
    display: none;
  }
  .heroHeadline {
    /* border: 1px solid red; */
    width: 100%;
    font-family: var(--primaryFont);
    margin-top: 120px;
    text-align: left;
    font-size: 62px;
    font-weight: 700;
    line-height: 70px;
  }
  .WordFlick {
    margin: left;
    text-align: left;
    font-family: var(--primaryFont);
    height: 30px;
    font-size: 40px;
    font-weight: 700;
    margin-top: 30px;
    /* opacity: 0.1; */
    /* border: 1px solid red; */
  }
  .letsConnect {
    width: 100%;
    /* border: 1px solid red; */
    color: #356cfb;
    background: none;
    font-family: var(--primaryFont);
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin-top: 30px;
    z-index: 999;
  }
}
@media (min-width: 679px) and (max-width: 1023px) {
  .heroSection {
    /* border: 1px solid red; */
    width: 100%;
    height: 430px;
    background: linear-gradient(to top, #c6c3ff 40%, #d7d4ff);
    display: flex;
    justify-content: center;
    position: relative; /* Set the position to relative */
  }
  
  .mainBox {
    height: 100%;
    width: 80%;
    position: relative; /* Set the position to relative */
    /* z-index: 1; */
  }

  .backgroundVideo {
    /* border: 1px solid red; */
    position: absolute;
    margin-top: 50px;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* z-index: 0; */
  }
  
  .container {
    width: 65%;
    height: 100%;
    flex-direction: column;
    justify-content: left;
    position: relative; /* Set the position to relative */
    z-index: 2; /* Add a higher z-index to place it above the video */
  }
  .rightSide {
    display: none;
  }
  .heroHeadline {
    /* border: 1px solid red; */
    width: 100%;
    font-family: var(--primaryFont);
    margin-top: 120px;
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }
  .WordFlick {
    margin: left;
    text-align: left;
    font-family: var(--primaryFont);
    height: 30px;
    font-size: 25px;
    font-weight: 700;
    margin-top: 20px;
    /* opacity: 0.1; */
    /* border: 1px solid red; */
  }
  .letsConnect {
    width: 100%;
    /* border: 1px solid red; */
    color: #356cfb;
    background: none;
    font-family: var(--primaryFont);
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin-top: 30px;
    z-index: 999;
  }
}
@media (min-width: 300px) and (max-width: 678px) {
  .heroSection {
    /* border: 1px solid red; */
    width: 100%;
    height: 711px;
    background-color: #d5d3ff;
  }
  .backgroundVideo{
    display: none;
  }
  .mainBox {
    height: 100%;
    /* border: 1px solid teal; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* margin: auto; */
  }
  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 45%;
    padding: 200px 0 0 0;
    display: flex;
    flex-direction: column;
  }
  .rightSide {
    display: block;
    width: 100%;
    height: 50%;
    /* border: 1px solid red; */
  }
  .heroHeadline {
    /* border: 1px solid red; */
    width: 100%;
    margin-top: -150px;
    text-align: center;
    font-size: 33px;
    font-weight: 700;
    line-height: 45px;
  }
  .letsConnect {
    height: 50px;
    /* border: 1px solid red; */
    width: 100%;
    color: #356cfb;
    background: none;
    font-family: var(--primaryFont);
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
  }
}