/* 320px — 480px: Mobile devices. 481px — 768px: iPads, Tablets. 769px — 1024px: Small screens, laptops. 1025px — 1200px: Desktops, large screens. */

/************ TABLE OF CONTENTS ***************


====================================================================
	Reset
====================================================================

 ***/

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-family: var(--primaryFont);
}

:root {
  /* Colors */
  --darkGreen: #0ADA5D;
  --lightGreen: #e6f6f4;
  --darkBlue: #6376ff;
  --lightBlue: #b6befa;
  --black: #181818;
  --lightGrey: #b3b3b3;
  --darkGrey: #687472;
  --white : #fff;
  --dashboardBg: #f5f5f9;

  /* Fonts */
  --primaryFont: "Manrope", sans-serif;
  --secondaryFont: "Poppins", sans-serif;
  /* scroll-behavior: unset; */
}

/* For WebKit-based browsers */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: var(--lightGrey);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--darkGrey);
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--black);
}

button,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.6em;
  font-family: var(--primaryFont);
}

textarea {
  overflow: hidden;
}

p {
  position: relative;
  line-height: 1.8em;
}

/* Typography */

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
