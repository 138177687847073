@media (min-width: 1024px) {
  .architecture {
    /* border: 1px solid red; */
    width: 100%;
    height: 608px;
    position: relative;
  }
  .container {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
    border-radius: 48px;
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    position: relative;
  }
  .container > div:nth-child(1) {
    position: absolute;
    height: 90%;
    width: 20%;
    border: 1px solid var(--grey-03, #dadae9);
    background: rgba(218, 218, 233, 0.2);
    top: 30px;
    left: 30px;
    border-radius: 25px;
  }
  .container > div:nth-child(2) {
    position: absolute;
    height: 67%;
    width: 50%;
    border: 1px solid var(--grey-03, #dadae9);
    background: rgba(218, 218, 233, 0.2);
    top: 30px;
    left: 300px;
    border-radius: 25px;
  }
  .container > div:nth-child(3) {
    position: absolute;
    height: 18%;
    width: 50%;
    border: 1px solid var(--grey-03, #dadae9);
    background: rgba(218, 218, 233, 0.2);
    bottom: 30px;
    left: 300px;
    border-radius: 25px;
  }
  .container > div:nth-child(4) {
    position: absolute;
    height: 90%;
    width: 20%;
    border: 1px solid var(--grey-03, #dadae9);
    background: rgba(218, 218, 233, 0.2);
    top: 30px;
    right: 30px;
    border-radius: 25px;
  }
  .firstBox {
    padding: 20px;
  }
  .firstBox > p {
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .firstBox > div {
    height: 104px;
    width: 148px;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-orange, #ffb04d);
    background: rgba(255, 176, 77, 0.2);
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-top: 80%;
  }
  .secondBox {
    padding: 20px;
    position: relative;
  }
  .secondBox > p {
    text-align: left;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .secondBox > div:nth-child(2) {
    position: absolute;
    height: 104px;
    width: 148px;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-orange, #ffb04d);
    background: rgba(255, 176, 77, 0.2);
    left: 4%;
    top: 20%;
  }
  .secondBox > div:nth-child(3) {
    position: absolute;
    height: 104px;
    width: 184px;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-green, #0ada5d);
    background: rgba(10, 218, 93, 0.2);
    left: 31.6%;
    top: 20%;
  }
  .secondBox > div:nth-child(4) {
    position: absolute;
    height: 104px;
    width: 185px;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
    right: 4%;
    top: 20%;
  }
  .secondBox > div:nth-child(5) {
    position: absolute;
    height: 76px;
    width: 274px;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: inline-flex;
    padding: 24px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-orange, #ffb04d);
    background: rgba(255, 176, 77, 0.2);
    left: 4%;
    top: 50%;
  }
  .secondBox > div:nth-child(6) {
    position: absolute;
    height: 76px;
    width: 259px;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-orange, #ffb04d);
    background: rgba(255, 176, 77, 0.2);
    right: 4%;
    top: 50%;
  }
  .secondBox > div:nth-child(7) {
    position: absolute;
    height: 76px;
    width: 549px;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-orange, #ffb04d);
    background: rgba(255, 176, 77, 0.2);
    left: 4%;
    bottom: 7%;
  }
  .thirdBox {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .thirdBox > div {
    height: 60px;
    width: 549px;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .foutBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .foutBox > div {
    height: 104px;
    width: 124px;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .arrow_container1 {
    background-color: #787895; /* Adjust the color of the line as needed */
    margin: 0 auto; /* Center the line horizontally in its container */
  }
  /* Create the arrowhead using ::before */
  .arrow_container1::before {
    content: "";
    position: absolute;
    left: -3px;
    width: 10px; /* Adjust the size of the arrowhead lines */
    height: 10px; /* Adjust the size of the arrowhead lines */
    background-color: transparent;
    border-left: 2px solid #787895; /* Adjust the size and color of the left line */
    border-bottom: 2px solid #787895; /* Adjust the size and color of the bottom line */
    transform: rotate(-225deg); /* Rotate the lines to form a 45-degree angle */
  }
  .arrow1 {
    width: 2px;
    height: 70px;
    position: absolute;
    top: 44%;
    left: 21.7%;
    transform: rotate(90deg);
  }
  .arrow2 {
    width: 2px;
    height: 85px;
    position: absolute;
    top: 42%;
    left: 78.3%;
    transform: rotate(90deg);
  }
  .arrow3 {
    width: 2px;
    height: 55px;
    position: absolute;
    top: 72%;
    left: 50%;
    transform: rotate(180deg);
  }
}
@media (min-width: 300px) and (max-width: 1023px) {
  .architecture {
    /* border: 1px solid red; */
    width: 100%;
    height: 950px;
    margin-top: 20px;
    position: relative;
  }
  .container {
    /* border: 1px solid black; */
    width: 95%;
    height: 100%;
    margin: auto;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 48px;
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    position: relative;
  }
  .firstBox {
    position: absolute;
    height: 12%;
    width: 90%;
    border: 1px solid var(--grey-03, #dadae9);
    background: rgba(218, 218, 233, 0.2);
    top: 15px;
    left: 15px;
    border-radius: 25px;
    padding: 18px;
    /* border: 1px solid red; */
  }
  .firstBox > p {
    margin-top: -8px;
    /* border: 1px solid red; */
    text-align: left;
  }
  .firstBox > div {
    /* border: 1px solid red; */
    display: flex;
    width: 95%;
    height: 54px;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid var(--accents-orange, #ffb04d);
    background: rgba(255, 176, 77, 0.2);
    margin-top: -10px;
  }
  .secondBox {
    padding: 20px;
    position: relative;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .secondBox > p {
    text-align: left;
  }
  .secondBox {
    border: 1px solid red;
    position: absolute;
    height: 61%;
    width: 90%;
    border: 1px solid var(--grey-03, #dadae9);
    background: rgba(218, 218, 233, 0.2);
    top: 180px;
    left: 15px;
    border-radius: 25px;
  }
  .secondBox > div:nth-child(2) {
    position: absolute;
    height: 104px;
    width: 40%;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-orange, #ffb04d);
    background: rgba(255, 176, 77, 0.2);
    left: 4%;
    top: 16%;
  }
  .secondBox > div:nth-child(3) {
    position: absolute;
    height: 104px;
    width: 49%;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-green, #0ada5d);
    background: rgba(10, 218, 93, 0.2);
    left: 47%;
    top: 16%;
  }
  .secondBox > div:nth-child(4) {
    position: absolute;
    height: 80px;
    width: 92%;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
    right: 4%;
    top: 36%;
  }
  .secondBox > div:nth-child(5) {
    position: absolute;
    height: 80px;
    width: 92%;
    display: inline-flex;
    padding: 24px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-orange, #ffb04d);
    background: rgba(255, 176, 77, 0.2);
    left: 4%;
    top: 52%;
  }
  .secondBox > div:nth-child(6) {
    position: absolute;
    height: 80px;
    width: 92%;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-orange, #ffb04d);
    background: rgba(255, 176, 77, 0.2);
    right: 4%;
    top: 68%;
  }
  .secondBox > div:nth-child(7) {
    position: absolute;
    height: 80px;
    width: 92%;
    display: inline-flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--accents-orange, #ffb04d);
    background: rgba(255, 176, 77, 0.2);
    left: 4%;
    bottom: 2%;
  }
  .thirdBox {
    position: absolute;
    height: 13%;
    width: 47%;
    border: 1px solid var(--grey-03, #dadae9);
    background: rgba(218, 218, 233, 0.2);
    bottom: 15px;
    left: 15px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .thirdBox > div {
    display: flex;
    width: 85%;
    height: 80%;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .foutBox {
    position: absolute;
    height: 13%;
    width: 39%;
    border: 1px solid var(--grey-03, #dadae9);
    background: rgba(218, 218, 233, 0.2);
    bottom: 15px;
    right: 15px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .foutBox > div {
    display: flex;
    width: 85%;
    height: 80%;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .arrow_container1 {
    background-color: #787895; /* Adjust the color of the line as needed */
    margin: 0 auto; /* Center the line horizontally in its container */
  }
  /* Create the arrowhead using ::before */
  .arrow_container1::before {
    content: "";
    position: absolute;
    left: -3px;
    width: 10px; /* Adjust the size of the arrowhead lines */
    height: 10px; /* Adjust the size of the arrowhead lines */
    background-color: transparent;
    border-left: 2px solid #787895; /* Adjust the size and color of the left line */
    border-bottom: 2px solid #787895; /* Adjust the size and color of the bottom line */
    transform: rotate(-225deg); /* Rotate the lines to form a 45-degree angle */
  }
  .arrow1 {
    width: 2px;
    height: 48px;
    position: absolute;
    top: 13.5%;
    left: 50%;
    transform: rotate(180deg);
  }
  .arrow2 {
    width: 2px;
    height: 49px;
    position: absolute;
    top: 80%;
    left: 30%;
    transform: rotate(180deg);
  }
  .arrow3 {
    width: 2px;
    height: 49px;
    position: absolute;
    top: 80%;
    left: 75%;
    transform: rotate(180deg);
  }
}
