.cuttingEdge {
  /* border: 1px solid red; */
  width: 100%;
  height: 262px;
  margin-top: -120px;
  z-index: 99999;
}
.container {
  /* border: 1px solid black; */
  width: 1200px;
  height: 100%;
  margin: auto;
  background-color: var(--white);
  border-radius: 48px 48px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  z-index: 3;
  position: relative;
}

.container > h6 {
  font-size: 16px;
  color: var(--darkGreen);
  font-family: var(--primaryFont);
  line-height: 24px; /* 150% */
  font-weight: 700;
}
.container > h1 {
  width: 588px;
  color: var(--black);
  text-align: center;
  font-family: Manrope;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 679px) and (max-width: 1023px){
  .cuttingEdge {
    /* border: 1px solid red; */
    width: 100%;
    height: 152px;
    margin-top: -100px;
    position: relative;
    z-index: 1;
  }
  .container {
    /* border: 1px solid black; */
    width: 95%;
    height: 100%;
    margin: auto;
    background-color: var(--white);
    border-radius: 48px 48px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .container > h6 {
    font-size: 16px;
    color: var(--darkGreen);
    font-family: var(--primaryFont);
    line-height: 24px; /* 150% */
    font-weight: 700;
  }
  .container > h1 {
    width: 100%;
    color: var(--black);
    text-align: center;
    font-family: Manrope;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

@media (min-width: 300px) and (max-width: 678px) {
  .cuttingEdge {
    /* border: 1px solid red; */
    width: 100%;
    height: 152px;
    margin-top: -100px;
    position: relative;
    z-index: 99999;
  }
  .container {
    /* border: 1px solid black; */
    width: 95%;
    height: 100%;
    margin: auto;
    background-color: var(--white);
    border-radius: 48px 48px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .container > h6 {
    font-size: 16px;
    color: var(--darkGreen);
    font-family: var(--primaryFont);
    line-height: 24px; /* 150% */
    font-weight: 700;
  }
  .container > h1 {
    width: 100%;
    color: var(--black);
    text-align: center;
    font-family: Manrope;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
