@media (min-width: 1024px) {
  .accelerate {
    /* border: 1px solid red; */
    width: 100%;
    height: 562px;
  }
  .container {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
    padding: 48px 122px;
    display: flex;
    gap: 24px;
    text-align: left;
  }
  .accelerateTransformation {
    width: 466px;
    height: 466px;
    border-radius: 25px;
  }
  .accelerateTransformationRight > p {
    color: var(--darkGreen);
    font-family: var(--primaryFont);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
  .accelerateTransformationRight > h1 {
    color: var(--black);
    font-family: var(--primaryFont);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  /* Apply these styles to your list items */
  .custom_list {
    margin-top: 24px;
  }
  .custom_list li {
    /* border: 1px solid red; */
    position: relative;
    list-style-type: none;
    padding-left: 24px; /* To make space for the bullet point */
    margin-bottom: 10px; /* Adjust as needed */
    font-size: 18px;
    font-family: var(--primaryFont);
    color: var(--black);
    font-weight: 400;
    line-height: 24px;
  }

  .custom_list li::before {
    content: ""; /* Empty content to add the bullet point */
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 24px;
    background-color: #e110f0;
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='24' fill='none'><circle opacity='0.1' cx='8' cy='12' r='8' fill='%23E110F0'/><rect x='5' y='12' width='4' height='4' transform='rotate(-45 5 12)' fill='%23E110F0'/></svg>");
    mask-size: cover;
  }
}
@media (min-width: 679px) and (max-width: 1023px){
  .accelerate {
    /* border: 1px solid red; */
    height: 500px;
    width: 100%;
    height: max-content;
  }
  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: left;
  }
  .accelerateTransformation {
    /* border: 1px solid black; */
    margin: auto;
    width: 80%;
    height: 50%;
    border-radius: 25px;
  }
  .accelerateTransformationRight{
    /* border: 1px solid red; */
    padding: 0 0 0 10%;
  }
  .accelerateTransformationRight > p {
    width: 100%;
    /* border: 1px solid black; */
    color: var(--darkGreen);
    font-family: var(--primaryFont);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
  .accelerateTransformationRight > h1 {
    /* border: 1px solid black; */
    color: var(--black);
    font-family: var(--primaryFont);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  /* Apply these styles to your list items */
  .custom_list {
    margin-top: 24px;
  }
  .custom_list li {
    /* border: 1px solid red; */
    position: relative;
    list-style-type: none;
    padding-left: 24px; /* To make space for the bullet point */
    margin-bottom: 10px; /* Adjust as needed */
    font-size: 22px;
    font-family: var(--primaryFont);
    color: var(--black);
    font-weight: 400;
    line-height: 24px;
  }

  .custom_list li::before {
    content: ""; /* Empty content to add the bullet point */
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 24px;
    background-color: #e110f0;
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='24' fill='none'><circle opacity='0.1' cx='8' cy='12' r='8' fill='%23E110F0'/><rect x='5' y='12' width='4' height='4' transform='rotate(-45 5 12)' fill='%23E110F0'/></svg>");
    mask-size: cover;
  }
}

@media (min-width: 300px) and (max-width: 678px) {
  .accelerate {
    /* border: 1px solid red; */
    height: 500px;
    width: 100%;
    height: max-content;
  }
  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: left;
  }
  .accelerateTransformation {
    /* border: 1px solid black; */
    width: 100%;
    height: 50%;
    border-radius: 25px;
  }
  .accelerateTransformationRight > p {
    width: 100%;
    /* border: 1px solid black; */
    color: var(--darkGreen);
    font-family: var(--primaryFont);
    font-size: 15px;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
  .accelerateTransformationRight > h1 {
    /* border: 1px solid black; */
    color: var(--black);
    font-family: var(--primaryFont);
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  /* Apply these styles to your list items */
  .custom_list {
    margin-top: 24px;
  }
  .custom_list li {
    /* border: 1px solid red; */
    position: relative;
    list-style-type: none;
    padding-left: 24px; /* To make space for the bullet point */
    margin-bottom: 10px; /* Adjust as needed */
    font-size: 18px;
    font-family: var(--primaryFont);
    color: var(--black);
    font-weight: 400;
    line-height: 24px;
  }

  .custom_list li::before {
    content: ""; /* Empty content to add the bullet point */
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 24px;
    background-color: #e110f0;
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='24' fill='none'><circle opacity='0.1' cx='8' cy='12' r='8' fill='%23E110F0'/><rect x='5' y='12' width='4' height='4' transform='rotate(-45 5 12)' fill='%23E110F0'/></svg>");
    mask-size: cover;
  }
}
