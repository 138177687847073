@media (min-width: 1024px) {
  .whatMakesUsDifferent {
    /* border: 1px solid red; */
    width: 100%;
    height: 977px;
    margin-top: 50px;
  }
  .container {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
  }
  .heading {
    margin: auto;
    /* border: 1px solid red; */
    width: 408px;
    margin-bottom: 20px;
  }
  .heading > p {
    font-family: var(--primaryFont);
    font-size: 16px;
    color: #0ada5d;
  }
  .heading > h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: normal;
    margin-top: 10px;
  }
  .details {
    display: flex;
    gap: 24px;
    padding: 48px 122px;
  }
  .details > img {
    height: 628px;
    width: 466px;
    border-radius: 25px;
  }
  .services {
    text-align: left;
  }
  .services > p:nth-child(1) {
    font-family: var(--primaryFont);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #0ada5d;
  }
  .services > h1 {
    font-family: var(--primaryFont);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    color: var(--black);
    line-height: 40px;
  }
  .services > p:nth-child(3) {
    margin-top: 10px;
    color: var(--black);
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
}
@media (min-width: 679px) and (max-width: 1023px){
  .whatMakesUsDifferent {
    /* border: 1px solid red; */
    width: 100%;
    height: max-content;
    margin-top: 50px;
  }
  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .heading {
    margin: auto;
    /* border: 1px solid red; */
    width: 100%;
    margin-bottom: 20px;
  }
  .heading > p {
    font-family: var(--primaryFont);
    font-size: 22px;
    color: #0ada5d;
  }
  .heading > h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: normal;
    margin-top: 10px;
  }
  .details {
    /* border: 1px solid red; */
    /* height: 80%; */
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 10px;
  }
  .details > img {
    width: 70%;
    border-radius: 25px;
    margin: auto;
  }
  .services {
    text-align: left;
    padding: 0 5% 0 5%;
  }
  .services > p:nth-child(1) {
    font-family: var(--primaryFont);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #0ada5d;
    margin-top: 30px;
  }
  .services > h1 {
    font-family: var(--primaryFont);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    color: var(--black);
    line-height: 40px;
  }
  .services > p:nth-child(3) {
    margin-top: 18px;
    color: var(--black);
    font-family: var(--primaryFont);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
}
@media (min-width: 300px) and (max-width: 678px) {
  .whatMakesUsDifferent {
    /* border: 1px solid red; */
    width: 100%;
    height: max-content;
    margin-top: 50px;
  }
  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .heading {
    margin: auto;
    /* border: 1px solid red; */
    width: 100%;
    margin-bottom: 20px;
  }
  .heading > p {
    font-family: var(--primaryFont);
    font-size: 18px;
    color: #0ada5d;
  }
  .heading > h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: normal;
    margin-top: 10px;
  }
  .details {
    /* border: 1px solid red; */
    /* height: 80%; */
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 10px;
  }
  .details > img {
    height: 50%;
    width: 100%;
    border-radius: 25px;
  }
  .services {
    text-align: left;
  }
  .services > p:nth-child(1) {
    font-family: var(--primaryFont);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #0ada5d;
    margin-top: 30px;
  }
  .services > h1 {
    font-family: var(--primaryFont);
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    color: var(--black);
    line-height: 40px;
  }
  .services > p:nth-child(3) {
    margin-top: 18px;
    color: var(--black);
    font-family: var(--primaryFont);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}
