@media (min-width: 1024px) {
  .features {
    /* border: 1px solid red; */
    /* background-image: url(../../../Assets/Images/Background_Image.png); */
    width: 100%;
    height: 600px;
    margin-top: 50px;
    /* margin: auto; */
  }
  .container {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    background-image: url(../../../Assets/Images/Background_Image.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
  }
  .section1 {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 50px;
  }
  .section1 > h1 {
    font-family: var(--primaryFont);
    font-size: 62px;
    font-weight: 700;
  }
  .section2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .section3 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
  }
  .eachBox {
    /* border: 1px solid red; */
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 96px;
    width: 368px;
    background-color: white;
    border-radius: 24px;
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
  }
  .eachBox > img {
    height: 42px;
    width: 42px;
  }
  .eachBox > p {
    margin-top: 15px;
    /* border: 1px solid red; */
    height: 48px;
    width: 278px;
    text-align: left;
    display: flex;
    align-items: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
  .hidden {
    visibility: hidden;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .features {
    /* border: 1px solid red; */
    background-image: url(../../../Assets/Images/Background_Image.png);
    width: 100%;
    height: max-content;
    margin-top: 50px;
  }
  .container {
    /* border: 1px solid black; */
    width: 95%;
    height: 100%;
    margin: auto;
  }
  .section1 > h1 {
    width: 80%;
    font-family: var(--primaryFont);
    font-size: 30px;
    font-weight: 700;
    margin: auto;
    margin-bottom: 30px;
  }
  .section1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .eachBox {
    /* border: 1px solid red; */
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    width: 80%;
    background-color: white;
    border-radius: 24px;
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    margin-bottom: 20px;
  }
  .eachBox > img {
    height: 80px;
    width: 80px;
  }
  .eachBox > p {
    margin-top: 15px;
    /* border: 1px solid red; */
    height: 48px;
    width: 80%;
    text-align: left;
    display: flex;
    align-items: center;
    font-family: var(--primaryFont);
    font-size: 28px;
    font-weight: 400;
    line-height: 35px;
  }
  .hidden {
    display: none;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .features {
    /* border: 1px solid red; */
    background-image: url(../../../Assets/Images/Background_Image.png);
    width: 100%;
    height: max-content;
    margin-top: 50px;
  }
  .container {
    /* border: 1px solid black; */
    width: 95%;
    height: 100%;
    margin: auto;
  }
  .section1 > h1 {
    width: 70%;
    font-family: var(--primaryFont);
    font-size: 30px;
    font-weight: 700;
    margin: auto;
    margin-bottom: 30px;
  }
  .section1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .eachBox {
    /* border: 1px solid red; */
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 96px;
    width: 326px;
    background-color: white;
    border-radius: 24px;
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    margin-bottom: 20px;
  }
  .eachBox > img {
    height: 42px;
    width: 42px;
  }
  .eachBox > p {
    margin-top: 15px;
    /* border: 1px solid red; */
    height: 48px;
    width: 278px;
    text-align: left;
    display: flex;
    align-items: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
  .hidden {
    display: none;
  }
}
