@media (min-width: 1024px) {
  .mainBox {
    height: 608px;
    width: 1200px;
    border-radius: 48px;
    background: #fff;
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    margin: auto;
    /* margin-top: 150px; */
    display: flex;
    justify-content: center;
    gap: 210px;
    padding: 20px 72px;
    position: relative;
  }
  .leftBox {
    width: 50%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 51px;
  }
  .leftBox > div > p {
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
  }
  .leftBox > div:nth-child(1) {
    width: 122px;
    height: 104px;
    display: inline-flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .leftBox > div:nth-child(2) {
    width: 146px;
    height: 132px;
    display: inline-flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .leftBox > div > img {
    height: 24px;
    width: 24px;
  }
  .rightBox {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  .rightBox > div {
    width: 482px;
    height: 181px;
    display: flex;
    padding: 15px 22px;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 13px; */
    border-radius: 24px;
    border: 1px solid var(--grey-03, #dadae9);
    background: rgba(218, 218, 233, 0.2);
  }
  .rightBox > div > p {
    color: var(--grey-grey-01, var(--grey-01, #27274c));
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .rightBox > div > p:nth-child(2) {
    display: none;
  }
  .rightParentBox {
    display: flex;
    gap: 16px;
  }
  .rightParentBox > div {
    width: 134px;
    height: 104px;
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .rightParentBox2 > div {
    border: 1px solid #e110f0;
    background: rgba(243, 26, 235, 0.2);
  }
  .rightParentBox3 > div {
    border: 1px solid #0ada5d;
    background: rgba(10, 218, 93, 0.2);
  }
  .rightParentBox > div > p {
    color: var(--grey-grey-01, var(--grey-01, #27274c));
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 127.273% */
  }
  .rightParentBox > div > img {
    height: 24px;
    width: 24px;
  }
  .siloLeftBox {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: left;
    width: 50%;
    padding: 50px 0 40px 0;
  }
  .siloLeftBox > div {
    width: 140px;
    height: 104px;
    display: inline-flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .siloLeftBox > div > p {
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
  }
  .siloLeftBox > div > img {
    height: 24px;
    width: 24px;
  }
  .pooledLeftBox {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: left;
    width: 50%;
    /* padding: 70px 0 10px 0; */
  }
  .pooledLeftBox > div {
    width: 140px;
    height: 104px;
    display: inline-flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .shadowBox1 {
    width: 140px;
    height: 104px;
    margin-left: -144px;
    margin-top: 4px;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
    z-index: 0.9;
  }
  .pooledLeftBox > div > p {
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
  }
  .pooledLeftBox > div > img {
    height: 24px;
    width: 24px;
  }
  .arrow_container1 {
    background-color: #356cfb; /* Adjust the color of the line as needed */
    margin: 0 auto; /* Center the line horizontally in its container */
  }
  /* Create the arrowhead using ::before */
  .arrow_container1::before {
    content: "";
    position: absolute;
    left: -3px;
    width: 10px; /* Adjust the size of the arrowhead lines */
    height: 10px; /* Adjust the size of the arrowhead lines */
    background-color: transparent;
    border-left: 2px solid #356cfb; /* Adjust the size and color of the left line */
    border-bottom: 2px solid #356cfb; /* Adjust the size and color of the bottom line */
    transform: rotate(-225deg); /* Rotate the lines to form a 45-degree angle */
  }
  .line_container {
    background-color: #356cfb;
    margin: 0 auto;
  }
  .arrow1 {
    width: 2px;
    height: 49px;
    position: absolute;
    top: 46.2%;
    left: 20%;
    transform: rotate(90deg);
  }
  .arrow2 {
    width: 2px;
    height: 230px;
    position: absolute;
    top: 31.2%;
    left: 44%;
    transform: rotate(90deg);
  }
  .arrow3 {
    width: 2px;
    height: 55px;
    position: absolute;
    top: 15%;
    left: 51%;
    transform: rotate(90deg);
  }
  .arrow4 {
    width: 2px;
    height: 55px;
    position: absolute;
    top: 77%;
    left: 51%;
    transform: rotate(90deg);
  }
  .arrow5 {
    width: 2px;
    height: 215px;
    position: absolute;
    top: 17%;
    left: 44.3%;
    transform: rotate(30deg);
  }
  .arrow6 {
    width: 2px;
    height: 220px;
    position: absolute;
    top: 47.5%;
    left: 44.3%;
    transform: rotate(151deg);
  }
  .arrow7 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 20%;
    left: 67.5%;
    transform: rotate(90deg);
  }
  .arrow8 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 20%;
    left: 80%;
    transform: rotate(90deg);
  }
  .arrow9 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 51%;
    left: 67.5%;
    transform: rotate(90deg);
    background-color: #E110F0;
  }
  .arrow10 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 51%;
    left: 80%;
    transform: rotate(90deg);
    background-color: #E110F0;
  }
  .arrow11 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 83%;
    left: 67.5%;
    transform: rotate(90deg);
    background-color: #0ADA5D;
  }
  .arrow12 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 83%;
    left: 80%;
    transform: rotate(90deg);
    background-color: #0ADA5D;
  }
  .arrow13 {
    width: 2px;
    height: 430px;
    position: absolute;
    top: -16%;
    left: 35.7%;
    transform: rotate(90deg);
  }
  .arrow14 {
    width: 2px;
    height: 430px;
    position: absolute;
    top: 15%;
    left: 35.7%;
    transform: rotate(90deg);
  }
  .arrow15 {
    width: 2px;
    height: 430px;
    position: absolute;
    top: 47%;
    left: 35.7%;
    transform: rotate(90deg);
  }
  .arrow16 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 20%;
    left: 80%;
    transform: rotate(90deg);
  }
  .arrow17 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 20%;
    left: 67.5%;
    transform: rotate(90deg);
  }
  .arrow18 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 52%;
    left: 67.5%;
    transform: rotate(90deg);
    background-color: #E110F0;
  }
  .arrow19 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 52%;
    left: 80%;
    transform: rotate(90deg);
    background-color: #E110F0;
  }
  .arrow20 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 83%;
    left: 67.6%;
    transform: rotate(90deg);
    background-color: #0ADA5D;
  }
  .arrow21 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 83%;
    left: 80%;
    transform: rotate(90deg);
    background-color: #0ADA5D;
  }
  .arrow22 {
    width: 2px;
    height: 430px;
    position: absolute;
    top: 15%;
    left: 35.5%;
    transform: rotate(90deg);
  }
  .arrow23 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 51%;
    left: 67.5%;
    transform: rotate(90deg);
  }
  .arrow24 {
    width: 2px;
    height: 18px;
    position: absolute;
    top: 51%;
    left: 80%;
    transform: rotate(90deg);
  }
}
@media (min-width: 679px) and (max-width: 1023px) {
  .mainBox {
    height: 758px;
    width: 95%;
    border-radius: 48px;
    background: #fff;
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    margin: auto;
    padding: 5px 0 0 0;
    position: relative;
    /* border: 1px solid red; */
  }
  .leftBox {
    width: 100%;
    margin-top: 20px;
    /* border: 1px solid red; */
  }

  .leftBox > div > p {
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
  .leftBox > div:nth-child(1) {
    width: 50%;
    height: 101px;
    display: inline-flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .leftBox > div:nth-child(2) {
    width: 90%;
    height: 101px;
    margin-top: 40px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .leftBox > div > img {
    height: 24px;
    width: 24px;
  }
  .rightBox {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 80px;
  }
  .rightBox > div:nth-child(3) {
    border: 1px solid red;
    display: none;
  }

  .rightBox > div {
    width: 44%;
    height: 400px;
    display: flex;
    /* padding: 15px 22px; */
    flex-direction: column;
    align-items: center;
    /* gap: 13px; */
    border-radius: 24px;
    border: 1px solid var(--grey-03, #dadae9);
    background: rgba(218, 218, 233, 0.2);
  }
  .rightBox > div > p {
    width: 90%;
    color: var(--grey-grey-01, var(--grey-01, #27274c));
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-top: 20px;
    text-align: left;
    /* border: 1px solid red; */
  }
  .rightBox > div > p:nth-child(1) {
    display: none;
  }
  .rightParentBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .rightParentBox > div {
    width: 120px;
    height: 101px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .rightParentBox2 > div {
    border: 1px solid #e110f0;
    background: rgba(243, 26, 235, 0.2);
  }
  .rightParentBox3 > div {
    border: 1px solid #0ada5d;
    background: rgba(10, 218, 93, 0.2);
  }
  .rightParentBox > div > p {
    color: var(--grey-grey-01, var(--grey-01, #27274c));
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 127.273% */
  }
  .rightParentBox > div > img {
    height: 24px;
    width: 24px;
  }
  .siloLeftBox {
    /* border: 1px solid red; */
    display: flex;
    /* flex-direction: column; */
    justify-content: space-evenly;
    /* gap: px; */
    margin: auto;
    width: 92%;
    padding: 50px 0 40px 0;
  }
  .siloLeftBox > div {
    width: 135px;
    height: 104px;
    display: inline-flex;
    /* padding: 24px 16px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .siloLeftBox > div:nth-child(3) {
    display: none;
  }
  .siloLeftBox > div > p {
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
  .siloLeftBox > div > img {
    height: 24px;
    width: 24px;
  }
  .pooledLeftBox {
    /* border: 1px solid red; */
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    width: 50%;
    margin: auto;
    margin-top: 50px;
  }
  .pooledLeftBox > div {
    margin-left: 20px;
    width: 140px;
    height: 104px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .pooledLeftBox > div > p {
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
  .pooledLeftBox > div > img {
    height: 24px;
    width: 24px;
  }
  .arrow_container1 {
    background-color: #356cfb; /* Adjust the color of the line as needed */
    margin: 0 auto; /* Center the line horizontally in its container */
  }
  /* Create the arrowhead using ::before */
  .arrow_container1::before {
    content: "";
    position: absolute;
    left: -3px;
    width: 10px; /* Adjust the size of the arrowhead lines */
    height: 10px; /* Adjust the size of the arrowhead lines */
    background-color: transparent;
    border-left: 2px solid #356cfb; /* Adjust the size and color of the left line */
    border-bottom: 2px solid #356cfb; /* Adjust the size and color of the bottom line */
    transform: rotate(-225deg); /* Rotate the lines to form a 45-degree angle */
  }
  .line_container {
    background-color: #356cfb;
    margin: 0 auto;
  }
  .arrow1 {
    width: 2px;
    height: 39px;
    position: absolute;
    top: 16.5%;
    left: 50%;
    transform: rotate(180deg);
  }
  .arrow2 {
    width: 2px;
    height: 20px;
    position: absolute;
    top: 43%;
    left: 28%;
    transform: rotate(180deg);
  }
  .arrow3 {
    width: 2px;
    height: 20px;
    position: absolute;
    top: 43%;
    right: 28%;
    transform: rotate(180deg);
  }
  .arrow4 {
    visibility: hidden;
    width: 2px;
    height: 55px;
    position: absolute;
    top: 292%;
    left: 51%;
    transform: rotate(90deg);
  }
  .arrow5 {
    width: 2px;
    height: 172px;
    position: absolute;
    top: 30%;
    left: 38.5%;
    transform: rotate(80deg);
  }
  .arrow6 {
    width: 2px;
    height: 172px;
    position: absolute;
    top: 30%;
    right: 39%;
    transform: rotate(100deg);
  }
  .arrow7 {
    width: 2px;
    height: 27px;
    position: absolute;
    top: 35.5%;
    left: 49.7%;
    transform: rotate(180deg);
  }
  .arrow8 {
    width: 2px;
    height: 10px;
    position: absolute;
    top: 67.5%;
    left: 27%;
    transform: rotate(180deg);
  }
  .arrow9 {
    width: 2px;
    height: 10px;
    position: absolute;
    top: 82.3%;
    left: 27%;
    transform: rotate(180deg);
  }
  .arrow10 {
    width: 2px;
    height: 10px;
    position: absolute;
    top: 67.5%;
    right: 27%;
    transform: rotate(180deg);
    background-color: #E110F0;
  }
  .arrow11 {
    width: 2px;
    height: 10px;
    position: absolute;
    top: 82.3%;
    right: 27%;
    transform: rotate(180deg);
    background-color: #E110F0;
  }
  .arrow12 {
    visibility: hidden;
    width: 2px;
    height: 18px;
    position: absolute;
    top: 328%;
    left: 73.5%;
    transform: rotate(90deg);
    background-color: #0ADA5D;
  }
  .arrow13 {
    width: 2px;
    height: 120px;
    position: absolute;
    top: 21%;
    left: 28%;
    transform: rotate(180deg);
  }
  .arrow14 {
    width: 2px;
    height: 120px;
    position: absolute;
    top: 21%;
    right: 28%;
    transform: rotate(180deg);
  }
  .arrow15 {
    visibility: hidden;
    width: 2px;
    height: 430px;
    position: absolute;
    top: 298%;
    left: 38.7%;
    transform: rotate(90deg);
  }
  .arrow16 {
    width: 2px;
    height: 12px;
    position: absolute;
    top: 58.5%;
    left: 28%;
    transform: rotate(180deg);
  }
  .arrow17 {
    width: 2px;
    height: 12px;
    position: absolute;
    top: 73%;
    left: 28%;
    transform: rotate(180deg);
  }
  .arrow18 {
    width: 2px;
    height: 12px;
    position: absolute;
    top: 73%;
    right: 28%;
    transform: rotate(180deg);
    background-color: #E110F0;
  }
  .arrow19 {
    width: 2px;
    height: 12px;
    position: absolute;
    top: 58.5%;
    right: 28%;
    transform: rotate(180deg);
    background-color: #E110F0;
  }
  .arrow20 {
    visibility: hidden;
    width: 2px;
    height: 18px;
    position: absolute;
    top: 328%;
    left: 63.8%;
    transform: rotate(90deg);
    background-color: #0ADA5D;
  }
  .arrow21 {
    visibility: hidden;
    width: 2px;
    height: 18px;
    position: absolute;
    top: 328%;
    left: 73.5%;
    transform: rotate(90deg);
    background-color: #0ADA5D;
  }
  .arrow22 {
    width: 2px;
    height: 80px;
    position: absolute;
    top: 21%;
    left: 50%;
    transform: rotate(180deg);
  }
  .arrow23 {
    width: 2px;
    height: 13px;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: rotate(180deg);
  }
  .arrow24 {
    width: 2px;
    height: 13px;
    position: absolute;
    top: 68%;
    left: 50%;
    transform: rotate(180deg);
  }
}
@media (min-width: 300px) and (max-width: 678px) {
  .mainBox {
    height: 758px;
    width: 95%;
    border-radius: 48px;
    background: #fff;
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    margin: auto;
    padding: 5px 0 0 0;
    position: relative;
    /* border: 1px solid red; */
  }
  .leftBox {
    width: 100%;
    margin-top: 20px;
    /* border: 1px solid red; */
  }

  .leftBox > div > p {
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
  .leftBox > div:nth-child(1) {
    width: 50%;
    height: 101px;
    display: inline-flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .leftBox > div:nth-child(2) {
    width: 90%;
    height: 101px;
    margin-top: 40px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .leftBox > div > img {
    height: 24px;
    width: 24px;
  }
  .rightBox {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 80px;
  }
  .rightBox > div:nth-child(3) {
    border: 1px solid red;
    display: none;
  }

  .rightBox > div {
    width: 44%;
    height: 400px;
    display: flex;
    /* padding: 15px 22px; */
    flex-direction: column;
    align-items: center;
    /* gap: 13px; */
    border-radius: 24px;
    border: 1px solid var(--grey-03, #dadae9);
    background: rgba(218, 218, 233, 0.2);
  }
  .rightBox > div > p {
    width: 90%;
    color: var(--grey-grey-01, var(--grey-01, #27274c));
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-top: 20px;
    text-align: left;
    /* border: 1px solid red; */
  }
  .rightBox > div > p:nth-child(1) {
    display: none;
  }
  .rightParentBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .rightParentBox > div {
    width: 120px;
    height: 101px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .rightParentBox2 > div {
    border: 1px solid #e110f0;
    background: rgba(243, 26, 235, 0.2);
  }
  .rightParentBox3 > div {
    border: 1px solid #0ada5d;
    background: rgba(10, 218, 93, 0.2);
  }
  .rightParentBox > div > p {
    color: var(--grey-grey-01, var(--grey-01, #27274c));
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 127.273% */
  }
  .rightParentBox > div > img {
    height: 24px;
    width: 24px;
  }
  .siloLeftBox {
    /* border: 1px solid red; */
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    gap: 35px;
    margin: auto;
    width: 92%;
    padding: 50px 0 40px 0;
  }
  .siloLeftBox > div {
    width: 135px;
    height: 104px;
    display: inline-flex;
    /* padding: 24px 16px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .siloLeftBox > div:nth-child(3) {
    display: none;
  }
  .siloLeftBox > div > p {
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
  .siloLeftBox > div > img {
    height: 24px;
    width: 24px;
  }
  .pooledLeftBox {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: auto;
    margin-top: 50px;
  }
  .pooledLeftBox > div {
    margin-left: 20px;
    width: 140px;
    height: 104px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 2px solid var(--accents-blue, #356cfb);
    background: rgba(53, 108, 251, 0.2);
  }
  .pooledLeftBox > div > p {
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
  .pooledLeftBox > div > img {
    height: 24px;
    width: 24px;
  }
  .arrow_container1 {
    background-color: #356cfb; /* Adjust the color of the line as needed */
    margin: 0 auto; /* Center the line horizontally in its container */
  }
  /* Create the arrowhead using ::before */
  .arrow_container1::before {
    content: "";
    position: absolute;
    left: -3px;
    width: 10px; /* Adjust the size of the arrowhead lines */
    height: 10px; /* Adjust the size of the arrowhead lines */
    background-color: transparent;
    border-left: 2px solid #356cfb; /* Adjust the size and color of the left line */
    border-bottom: 2px solid #356cfb; /* Adjust the size and color of the bottom line */
    transform: rotate(-225deg); /* Rotate the lines to form a 45-degree angle */
  }
  .line_container {
    background-color: #356cfb;
    margin: 0 auto;
  }
  .arrow1 {
    width: 2px;
    height: 39px;
    position: absolute;
    top: 16.5%;
    left: 50%;
    transform: rotate(180deg);
  }
  .arrow2 {
    width: 2px;
    height: 20px;
    position: absolute;
    top: 43%;
    left: 28%;
    transform: rotate(180deg);
  }
  .arrow3 {
    width: 2px;
    height: 20px;
    position: absolute;
    top: 43%;
    right: 28%;
    transform: rotate(180deg);
  }
  .arrow4 {
    visibility: hidden;
    width: 2px;
    height: 55px;
    position: absolute;
    top: 292%;
    left: 51%;
    transform: rotate(90deg);
  }
  .arrow5 {
    width: 2px;
    height: 85px;
    position: absolute;
    top: 35.5%;
    left: 38.5%;
    transform: rotate(70deg);
  }
  .arrow6 {
    width: 2px;
    height: 85px;
    position: absolute;
    top:35.5%;
    right: 38.5%;
    transform: rotate(110deg);
  }
  .arrow7 {
    width: 2px;
    height: 27px;
    position: absolute;
    top: 35.5%;
    left: 49.7%;
    transform: rotate(180deg);
  }
  .arrow8 {
    width: 2px;
    height: 10px;
    position: absolute;
    top: 67.5%;
    left: 27%;
    transform: rotate(180deg);
  }
  .arrow9 {
    width: 2px;
    height: 10px;
    position: absolute;
    top: 82.3%;
    left: 27%;
    transform: rotate(180deg);
  }
  .arrow10 {
    width: 2px;
    height: 10px;
    position: absolute;
    top: 67.5%;
    right: 27%;
    transform: rotate(180deg);
    background-color: #E110F0;
  }
  .arrow11 {
    width: 2px;
    height: 10px;
    position: absolute;
    top: 82.3%;
    right: 27%;
    transform: rotate(180deg);
    background-color: #E110F0;
  }
  .arrow12 {
    visibility: hidden;
    width: 2px;
    height: 18px;
    position: absolute;
    top: 328%;
    left: 73.5%;
    transform: rotate(90deg);
    background-color: #0ADA5D;
  }
  .arrow13 {
    width: 2px;
    height: 120px;
    position: absolute;
    top: 21%;
    left: 28%;
    transform: rotate(180deg);
  }
  .arrow14 {
    width: 2px;
    height: 120px;
    position: absolute;
    top: 21%;
    right: 28%;
    transform: rotate(180deg);
  }
  .arrow15 {
    visibility: hidden;
    width: 2px;
    height: 430px;
    position: absolute;
    top: 298%;
    left: 38.7%;
    transform: rotate(90deg);
  }
  .arrow16 {
    width: 2px;
    height: 12px;
    position: absolute;
    top: 58.5%;
    left: 28%;
    transform: rotate(180deg);
  }
  .arrow17 {
    width: 2px;
    height: 12px;
    position: absolute;
    top: 73%;
    left: 28%;
    transform: rotate(180deg);
  }
  .arrow18 {
    width: 2px;
    height: 12px;
    position: absolute;
    top: 73%;
    right: 28%;
    transform: rotate(180deg);
    background-color: #E110F0;
  }
  .arrow19 {
    width: 2px;
    height: 12px;
    position: absolute;
    top: 58.5%;
    right: 28%;
    transform: rotate(180deg);
    background-color: #E110F0;
  }
  .arrow20 {
    visibility: hidden;
    width: 2px;
    height: 18px;
    position: absolute;
    top: 328%;
    left: 63.8%;
    transform: rotate(90deg);
    background-color: #0ADA5D;
  }
  .arrow21 {
    visibility: hidden;
    width: 2px;
    height: 18px;
    position: absolute;
    top: 328%;
    left: 73.5%;
    transform: rotate(90deg);
    background-color: #0ADA5D;
  }
  .arrow22 {
    width: 2px;
    height: 80px;
    position: absolute;
    top: 21%;
    left: 50%;
    transform: rotate(180deg);
  }
  .arrow23 {
    width: 2px;
    height: 13px;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: rotate(180deg);
  }
  .arrow24 {
    width: 2px;
    height: 13px;
    position: absolute;
    top: 68%;
    left: 50%;
    transform: rotate(180deg);
  }
}
