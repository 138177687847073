@media (min-width: 1024px) {
  .home {
    font-family: var(--primaryFont);
  }

  .superbox {
    /* border: 10px solid red; */
    width: 100%;
    height: 881px;
    background-image: url(../../Assets/Images/Background_Image.png);
  }
  .mainbox1 {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
  }

  .content_box {
    /* border: 1px solid red; */
    text-align: left;
  }
  .lets_connect {
    color: #e110f0;
    font-size: 18px;
    font-weight: 500;
    margin-top: 4%;
    /* left: -41%; */
  }
  .Contactus {
    font-size: 68px;
    font-weight: 700;
    color: #27274c;
    font-family: var(--primaryFont);
  }

  .lets_collaborate {
    font-family: var(--primaryFont);
    font-size: 20px;
    font-weight: 700;
    /* text-align: left; */
    margin-left: 6%;
    color: #660972;
    /* border: 1px solid red; */
  }

  .Discover_the_possibilities {
    /* border: 1px solid red; */
    font-family: var(--primaryFont);
    font-size: 22px;
    font-weight: 700;
    /* text-align: left; */
    color: #660972;
    margin-left: 6%;
  }

  .custom_list {
    margin-top: 24px;
  }

  .custom_list li {
    /* border: 1px solid red; */
    position: relative;
    list-style-type: none;
    padding-left: 24px; /* To make space for the bullet point */
    margin-bottom: 10px; /* Adjust as needed */
    font-size: 16px;
    font-family: var(--primaryFont);
    color: var(--black);
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .custom_list li::before {
    content: ""; /* Empty content to add the bullet point */
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 24px;
    background-color: #e110f0;
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='24' fill='none'><circle opacity='0.1' cx='8' cy='12' r='8' fill='%23E110F0'/><rect x='5' y='12' width='4' height='4' transform='rotate(-45 5 12)' fill='%23E110F0'/></svg>");
    mask-size: cover;
  }

  .form_box {
    position: absolute;
    top: 600px;
    left: 70%;
    width: 29%;
    /* height: 50%; */

    transform: translate(-50%, -50%);
    color: black;
    /* border: 1px solid green; */
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(15, 14, 14, 0.548) 0px 3px 10px;
  }

  .form_input_main_box {
    /* border: 2px solid black; */
    width: 80%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .form_label {
    text-align: left;
    margin-top: 10px;
    width: 100%;
    font-family: var(--primaryFont);
    font-size: 16px;
    font-weight: 700;
  }
  .button {
    margin-top: 10px;
    width: 100%;
    border-radius: 11px;
    background: var(
      --gradients-gradient-4,
      linear-gradient(85deg, #ffb04d -0.97%, #fb686a 100%)
    );
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .superbox {
    /* border: 10px solid red; */
    width: 100%;
    height: max-content;
    background-image: url(../../Assets/Images/Background_Image.png);
  }
  .mainbox1 {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .content_box {
    /* border: 1px solid red; */
    text-align: center;
  }
  .lets_connect {
    color: #e110f0;
    font-size: 18px;
    font-weight: 500;
    margin-top: 4%;
    /* left: -41%; */
  }
  .Contactus {
    font-size: 32px;
    font-weight: 700;
    color: #27274c;
    font-family: var(--primaryFont);
  }
  .custom_list {
    margin-top: 24px;
  }

  .custom_list li {
    /* border: 1px solid red; */
    position: relative;
    list-style-type: none;
    padding-left: 15px; /* To make space for the bullet point */
    margin-bottom: 10px; /* Adjust as needed */
    font-size: 24px;
    font-family: var(--primaryFont);
    color: var(--black);
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }

  .custom_list li::before {
    content: ""; /* Empty content to add the bullet point */
    position: absolute;
    top: 70%;
    left: 0;
    transform: translateY(-150%);
    width: 16px;
    height: 24px;
    background-color: #e110f0;
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='24' fill='none'><circle opacity='0.1' cx='8' cy='12' r='8' fill='%23E110F0'/><rect x='5' y='12' width='4' height='4' transform='rotate(-45 5 12)' fill='%23E110F0'/></svg>");
    mask-size: cover;
    margin-left: -15px;
  }
  .lets_collaborate {
    font-family: var(--primaryFont);
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    margin-left: 6%;
    color: #660972;
    /* border: 1px solid red; */
  }

  .Discover_the_possibilities {
    /* border: 1px solid red; */
    width: 80%;
    font-family: var(--primaryFont);
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    color: #660972;
    margin-left: 6%;
  }
  .form_box {
    width: 95%;
    color: black;
    margin: auto;
    padding: 1px;
    /* border: 1px solid green; */
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(15, 14, 14, 0.24) 0px 3px 8px;
  }

  .form_input_main_box {
    /* border: 2px solid black; */
    width: 80%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .form_label {
    width: 100%;
    text-align: left;
    /* border: 1px solid red; */
    margin-top: 10px;
    font-family: var(--primaryFont);
    font-size: 20px;
    font-weight: 700;
  }
  .recaptcha {
    /* border: 1px solid red; */
    /* margin-left: -30px; */
  }
  .button {
    font-size: 40px;
    height: 40px;
    margin-top: 10px;
    width: 100%;
    border-radius: 11px;
    background: var(
      --gradients-gradient-4,
      linear-gradient(85deg, #ffb04d -0.97%, #fb686a 100%)
    );
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .superbox {
    /* border: 10px solid red; */
    width: 100%;
    height: max-content;
    background-image: url(../../Assets/Images/Background_Image.png);
  }
  .mainbox1 {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .content_box {
    /* border: 1px solid red; */
    text-align: center;
  }
  .lets_connect {
    color: #e110f0;
    font-size: 18px;
    font-weight: 500;
    margin-top: 4%;
    /* left: -41%; */
  }
  .Contactus {
    font-size: 32px;
    font-weight: 700;
    color: #27274c;
    font-family: var(--primaryFont);
  }
  .custom_list {
    margin-top: 24px;
  }

  .custom_list li {
    /* border: 1px solid red; */
    position: relative;
    list-style-type: none;
    padding-left: 15px; /* To make space for the bullet point */
    margin-bottom: 10px; /* Adjust as needed */
    font-size: 16px;
    font-family: var(--primaryFont);
    color: var(--black);
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .custom_list li::before {
    content: ""; /* Empty content to add the bullet point */
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-150%);
    width: 16px;
    height: 24px;
    background-color: #e110f0;
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='24' fill='none'><circle opacity='0.1' cx='8' cy='12' r='8' fill='%23E110F0'/><rect x='5' y='12' width='4' height='4' transform='rotate(-45 5 12)' fill='%23E110F0'/></svg>");
    mask-size: cover;
    margin-left: -15px;
  }
  .lets_collaborate {
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-left: 6%;
    color: #660972;
    /* border: 1px solid red; */
  }

  .Discover_the_possibilities {
    /* border: 1px solid red; */
    width: 80%;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #660972;
    margin-left: 6%;
  }
  .form_box {
    width: 95%;
    color: black;
    margin: auto;
    padding: 1px;
    /* border: 1px solid green; */
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(15, 14, 14, 0.24) 0px 3px 8px;
  }

  .form_input_main_box {
    /* border: 2px solid black; */
    width: 80%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .form_label {
    width: 100%;
    text-align: left;
    /* border: 1px solid red; */
    margin-top: 10px;
    font-family: var(--primaryFont);
    font-size: 16px;
    font-weight: 700;
  }
  .recaptcha {
    /* border: 1px solid red; */
    /* margin-left: -30px; */
  }
  .button {
    margin-top: 10px;
    width: 100%;
    border-radius: 11px;
    background: var(
      --gradients-gradient-4,
      linear-gradient(85deg, #ffb04d -0.97%, #fb686a 100%)
    );
  }
}
