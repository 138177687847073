@media (min-width: 1024px) {
  .superbox {
    /* border: 10px solid red; */
    width: 100%;
    height: max-content;
    background-image: url(../../Assets//Images/Background_Image.png);
  }

  .mainbox {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
  }
  .about_us_text_box {
    /* border: 10px solid red; */
    position: relative;
    height: 1300px;
    margin-top: 30px;
    width: 100%;
    text-align: left;
  }
  .about_us_text {
    color: #e73cf2;
    font-size: 18px;
    font-weight: 650;
  }
  .aboutus_heading {
    font-weight: 710;
  }
  .Through_empowerment_text {
    width: 37%;
    font-weight: 460;
  }
  .aboutus_first_image_box {
    position: absolute;
    top: 350px;
    left: 25%;
    /* border: 1px solid red; */
  }

  .first_image {
    width: 368px;
  }

  .aboutus_second_image_box {
    position: absolute;
    top: 250px;
    left: 52%;

    /* height: 200px; */
    /* border: 1px solid red; */
  }
  .second_image {
    width: 368px;
    height: 510px;
  }
  .aboutus_third_image_box {
    position: absolute;
    top: 800px;
    left: 25%;
    width: 368px;
  }
  .aboutus_fourth_image_box {
    position: absolute;
    top: 720px;
    left: 52%;
    /* border: 1px solid red; */
  }
  .fourth_image {
    height: 510px;
  }
  .our_team_box {
    width: 100%;
    height: max-content;
    /* position: absolute; */
    top: 350%;
    left: 0%;
    /* border: 1px solid rebeccapurple; */
  }
  .who {
    text-align: center;
    color: #98eebb;
    font-size: 16px;
    font-weight: 700;
  }
  .our_team {
    font-size: 42px;
    font-weight: 700;
    font-family: var(--primaryFont);
  }
  .our_team_mainbox {
    margin: auto;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* flex-wrap: wrap; */
    gap: 40px;
  }
  .eachBox {
    /* width: 16%; */
    width: 200px;
    border-radius: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    /* border: 1px solid red; */
  }
  .photo_span {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  } 
  .personal_image {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
  .country {
    /* border: 1px solid red; */
    /* margin-top: 10px; */
    /* display: flex; */
    /* vertical-align: top; */
    justify-content: right;
    /* padding: 0 20px; */
    font-size: 10px;
    font-weight: 750;
    font-family: var(--primaryFont);
    color: blue;
  }  
  .founder_name_text {
    /* border: 1px solid red; */
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
    padding: 0 20px;
    font-size: 13px;
    font-weight: 650;
    font-family: var(--primaryFont);
    font-style: normal;
  }
  .founder_text {
    width: 100%;
    /* text-align: left; */
    display: flex;
    justify-content: center;
    /* padding: 0 0 0 20px; */
    /* border: 1px solid red; */
    margin-top: -30px;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--primaryFont);
    /* color: #ef81f6; */
  }
  .founder_text > a {
    height: 20px;
    margin-left: 5px;
    margin-right: 10px;
    /* border: 1px solid red; */
    /* margin-top: -10px; */
    font-size: 15px;
    color: #0a66c2;
  }  
  /* .founder_desc {
    width: 100%;
    text-align: justify;
    padding: 0 20px 0 20px;
    margin-top: -10px;
    font-size: 14px;
    font-style: italic;
    line-height: 20px;
    font-family: var(--secondaryFont);
  } */
}
@media (min-width: 769px) and (max-width: 1023px) {
  .superbox {
    /* border: 1px solid red; */
    width: 100%;
    height: max-content;
  }

  .mainbox {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .about_us_text_box {
    background-image: url(../../Assets//Images/Background_Image.png);
    margin-top: 30px;
    width: 100%;
    text-align: center;
  }
  .about_us_text {
    color: #e73cf2;
    font-size: 18px;
    font-weight: 650;
  }
  .aboutus_heading {
    margin: auto;
    font-family: var(--primaryFont);
    /* border: 1px solid red; */
    text-align: center;
    font-size: 40px;
    width: 90%;
    font-weight: 700;
  }
  .Through_empowerment_text {
    margin: auto;
    width: 90%;
    font-family: var(--primaryFont);
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    line-height: 30px;
    margin-top: 20px;
  }
  .aboutus_first_image_box {
    margin-top: 50px;
  }
  .aboutus_second_image_box {
    display: none;
  }
  .aboutus_fourth_image_box {
    display: none;
  }
  .who {
    text-align: center;
    color: #98eebb;
    font-size: 16px;
    font-weight: 700;
  }
  .our_team {
    /* border: 1px solid red; */
    font-size: 42px;
    font-weight: 700;
    font-family: var(--primaryFont);
  }
  .our_team_mainbox {
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    gap: 20px;
    /* display: flex; */
    
  }
  .eachBox {
    width: 200px;
    border-radius: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    /* border: 1px solid red; */
  }
  .personal_image {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
  .founder_name_text {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 650;
    font-family: var(--primaryFont);
  }
  .founder_text {
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: center;
    padding: 0 0 0 20px;
    /* border: 1px solid red; */
    margin-top: -30px;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--primaryFont);
    /* color: #ef81f6; */
  }
  .founder_text > a {
    height: 20px;
    margin-left: 5px;
    margin-right: 10px;
    /* border: 1px solid red; */
    /* margin-top: -10px; */
    font-size: 15px;
    color: #0a66c2;
  }  
}
@media (min-width: 320px) and (max-width: 768px) {
  .superbox {
    /* border: 1px solid red; */
    width: 100%;
    height: max-content;
  }

  .mainbox {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .about_us_text_box {
    background-image: url(../../Assets//Images/Background_Image.png);
    margin-top: 30px;
    width: 100%;
    text-align: center;
  }
  .about_us_text {
    color: #e73cf2;
    font-size: 18px;
    font-weight: 650;
  }
  .aboutus_heading {
    margin: auto;
    font-family: var(--primaryFont);
    /* border: 1px solid red; */
    text-align: center;
    font-size: 32px;
    width: 90%;
    font-weight: 700;
  }
  .Through_empowerment_text {
    margin: auto;
    width: 90%;
    font-family: var(--primaryFont);
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    line-height: 25px;
    margin-top: 20px;
  }
  .aboutus_first_image_box {
    margin-top: 50px;
  }
  .aboutus_second_image_box {
    display: none;
  }
  .aboutus_fourth_image_box {
    display: none;
  }
  .who {
    text-align: center;
    color: #98eebb;
    font-size: 16px;
    font-weight: 700;
  }
  .our_team {
    font-size: 42px;
    font-weight: 700;
    font-family: var(--primaryFont);
  }
  .our_team_mainbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .eachBox {
    width: 200px;
    border-radius: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    /* border: 1px solid red; */
  }
  .personal_image {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
  .founder_name_text {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 650;
    font-family: var(--primaryFont);
  }
  .founder_text {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: center;
    padding: 0 0 0 20px;
    /* border: 1px solid red; */
    margin-top: -30px;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--primaryFont);
    /* color: #ef81f6; */
  }
  .founder_text > a {
    height: 20px;
    margin-left: 5px;
    margin-right: 10px;
    /* border: 1px solid red; */
    /* margin-top: -10px; */
    font-size: 15px;
    color: #0a66c2;
  } 
}
