@media (min-width: 1024px) {
  /* ItemFilter.css */

  .cherryPick {
    /* border: 1px solid red; */
    width: 100%;
    height: max-content;
  }

  .container {
    /* border: 1px solid black; */
    width: 1200px;
    margin: auto;
    margin-top: 50px;
    /* padding: 20px; */
  }

  .heading {
    margin: auto;
    /* border: 1px solid red; */
    width: 408px;
    margin-bottom: 20px;
  }

  .heading > p {
    font-family: var(--primaryFont);
    font-size: 16px;
    color: #0ada5d;
  }

  .heading > h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: normal;
    margin-top: 10px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .button {
    border: none;
    background: none;
    margin: 0 10px;
    padding: 5px 10px;
    font-size: 22px;
    font-weight: 700;
    font-family: var(--primaryFont);
    line-height: 28px;
    cursor: pointer;
  }

  .button.active {
    text-decoration: none;
    background: linear-gradient(to right, #356cfb, #b104c8);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    position: relative;
  }

  .button.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #356cfb, #b104c8);
    z-index: -1;
  }

  .itemBox {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
    gap: 24px;
    margin-top: 50px;
    /* overflow-x: scroll; */
  }

  .item {
    border-radius: 25px;
    height: 172px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    transition: transform 0.1s, box-shadow 0.3s;
    padding: 0 10px;
    position: relative
  }
  .item > img{
    position: absolute;
    top: 25px;
    height: 40px;
    width: 40px;
  }
  .item > p {
    position: absolute;
    top: 70px;
    /* border: 1px solid red; */
    width: 95%;
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    padding: 5px;

  }

  .item:hover {
    transform: translateY(10px);
    box-shadow: 0px 10px 20px rgba(69, 117, 183, 0.25);
  }
}
@media (min-width: 679px) and (max-width: 1023px){
  .cherryPick {
    /* border: 1px solid red; */
    width: 100%;
    height: max-content;
  }

  .container {
    padding: 0 10px;
    /* border: 1px solid black; */
    width: 100%;
    margin: auto;
    margin-top: 50px;
  }

  .heading {
    margin: auto;
    /* border: 1px solid red; */
    width: 100%;
    margin-bottom: 20px;
  }

  .heading > p {
    font-family: var(--primaryFont);
    font-size: 20px;
    color: #0ada5d;
  }

  .heading > h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: normal;
    margin-top: 10px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .button {
    border: none;
    background: none;
    margin: 0 5px;
    /* padding: 5px 10px; */
    font-size: 22px;
    font-weight: 700;
    font-family: var(--primaryFont);
    line-height: 28px;
    cursor: pointer;
  }

  .button.active {
    text-decoration: none;
    background: linear-gradient(to right, #356cfb, #b104c8);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    position: relative;
  }

  .button.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #356cfb, #b104c8);
    z-index: -1;
  }

  .itemBox {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(auto-fill, minmax(180px, 2fr));
    gap: 30px;
    margin-top: 50px;
    /* overflow-x: scroll; */
  }

  .item {
    border-radius: 25px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    transition: transform 0.1s, box-shadow 0.3s;
    /* box-sizing: border-box; */
    padding: 5px;
  }
  .item > img {
    /* border: 1px solid red; */
    height: 70px;
    width: 70px;
  }
  .item > p {
    /* border: 1px solid red; */
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .item:hover {
    transform: translateY(10px);
    box-shadow: 0px 10px 20px rgba(69, 117, 183, 0.25);
  }
}
@media (min-width: 300px) and (max-width: 678px) {
  .cherryPick {
    /* border: 1px solid red; */
    width: 100%;
    height: max-content;
  }

  .container {
    padding: 0 10px;
    /* border: 1px solid black; */
    width: 100%;
    margin: auto;
    margin-top: 50px;
  }

  .heading {
    margin: auto;
    /* border: 1px solid red; */
    width: 100%;
    margin-bottom: 20px;
  }

  .heading > p {
    font-family: var(--primaryFont);
    font-size: 15px;
    color: #0ada5d;
  }

  .heading > h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: normal;
    margin-top: 10px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .button {
    border: none;
    background: none;
    margin: 0 5px;
    /* padding: 5px 10px; */
    font-size: 15px;
    font-weight: 700;
    font-family: var(--primaryFont);
    line-height: 28px;
    cursor: pointer;
  }

  .button.active {
    text-decoration: none;
    background: linear-gradient(to right, #356cfb, #b104c8);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    position: relative;
  }

  .button.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #356cfb, #b104c8);
    z-index: -1;
  }

  .itemBox {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(auto-fill, minmax(150px, 2fr));
    gap: 10px;
    margin-top: 50px;
    /* overflow-x: scroll; */
  }

  .item {
    border-radius: 25px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 44px 0px rgba(69, 117, 183, 0.2);
    transition: transform 0.1s, box-shadow 0.3s;
    /* box-sizing: border-box; */
    padding: 5px;
  }
  .item > img {
    /* border: 1px solid red; */
    height: 50px;
    width: 50px;
  }
  .item > p {
    /* border: 1px solid red; */
    text-align: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .item:hover {
    transform: translateY(10px);
    box-shadow: 0px 10px 20px rgba(69, 117, 183, 0.25);
  }
}
