@media (min-width: 1024px) {
  .footer {
    /* border: 1px solid red; */
    width: 100%;
    height: 272px;
    background-image: url(../../Assets/Images/footerBackground.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 100px;
    scroll-behavior: smooth;
  }

  .container {
    /* border: 1px solid black; */
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--white);
  }

  .footerLogo {
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
  }

  .footerLogo>div {
    display: flex;
    gap: 20px;
    font-size: 24px;
  }

  .footerOptions {
    font-size: 14px;
    margin-top: 30px;
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
  }

  .footerOptions>div {
    display: flex;
    gap: 30px;
  }

  .footerOptions>div>a {
    text-decoration: none;
    color: white;
  }

  .footerOptions>div>a:hover {
    text-decoration: underline;
  }

  .footerAddress {
    font-size: 10px;
  }

}

@media (min-width: 679px) and (max-width: 1023px) {
  .footer {
    /* border: 1px solid red; */
    width: 100%;
    height: 500px;
    background-image: url(../../Assets/Images/mobileFooter.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 100px;
  }

  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--white);
  }

  .footerLogo {
    width: 95%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    gap: 150px;
    align-items: center;
    margin-top: 50px;
  }

  .footerLogo>div {
    display: flex;
    gap: 20px;
    font-size: 32px;
  }

  .footerOptions {
    margin: auto;
    font-size: 22px;
    margin-top: 30px;
    width: 80%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .footerOptions>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 0 0 0 50px;
  }

  .footerOptions>div>a {
    /* border: 1px solid red; */
    text-align: left;
    width: 40%;
    text-decoration: none;
    color: white;
  }

  .footerOptions>div>a:hover {
    text-decoration: underline;
  }
  .footerAddress {
    margin: auto;
    font-size: 10px;
  }  
}

@media (min-width: 300px) and (max-width: 678px) {
  .footer {
    /* border: 1px solid red; */
    width: 100%;
    height: 455px;
    background-image: url(../../Assets/Images/mobileFooter.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 100px;
  }

  .container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--white);
  }

  .footerLogo {
    width: 95%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    margin-top: 100px;
  }

  .footerLogo>div {
    display: flex;
    gap: 20px;
    font-size: 24px;
  }

  .footerOptions {
    font-size: 14px;
    margin-top: 30px;
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .footerOptions>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .footerOptions>div>a {
    /* border: 1px solid red; */
    text-align: left;
    width: 40%;
    text-decoration: none;
    color: white;
  }

  .footerOptions>div>a:hover {
    text-decoration: underline;
  }

  .footerAddress {
    font-size: 10px;
    justify-content: right;
  }
}