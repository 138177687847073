.Navbar {
  /* border: 1px solid red; */
  width: 100%;
  height: 80px;
  background: linear-gradient(to right, #356cfb, #b104c8);
}
.container {
  /* border: 1px solid blue; */
  height: 100%;
  width: 96%;
  /* max-width: 1440px; */
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactButton {
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 5px 24px;
  font-size: 18px;
  cursor: pointer;
  background-image: linear-gradient(to right, #FFB04D, #FB686A);
}
.buttonLink {
  color: #ffffff;
  text-decoration: none;
}
.nav-logo {
  /* border: 1px solid blue; */
  font-weight: 700;
  font-size: 21px;
  color: #ffffff;
}

.nav-items > .link {
  color: #e2d3f8;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  margin: 15px;
  position: relative;
  opacity: 0.9;
  padding: 8px;
}

.nav-items > .link:hover {
  opacity: 1;
  color: white;
}

/* .nav-items > .link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: all 0.45s;
}

.nav-items > .link:hover::before {
  width: 100%;
} */

.nav-toggle {
  display: none;
}
@media (min-width: 679px) and (max-width: 1023px){
  .Navbar {
    /* border: 1px solid red; */
    width: 100%;
    height: 70px;
    background: linear-gradient(to right, #356cfb, #b104c8);
  }
  .container {
    /* border: 1px solid blue; */
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-logo {
    margin: 15px 0 15px 15px; /* Adjust margin to push logo to the left */
  }
  .contactButton {
    width: 94%;
    margin-left: 3%;
    display: block; /* Set the button to take full width */
    /* margin: 0 20%; */
  }
  .buttonLink {
    color: #ffffff;
    text-decoration: none;
  }
  .Navbar {
    padding: 0;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, #356cfb, #b104c8);
  }
  .nav-items {
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    background: #356cfb;
    left: 0;
    width: 100%;
    height: 50%;
    transform: translateX(-100%);
    transition: all 0.45s;
    z-index: 9999;
    margin-top: 5px;
  }
  /* .nav-items > .link {
    color: #e2d3f8;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    margin: 15px;
    position: relative;
    opacity: 0.9;
    padding: 8px;
  } */
  .nav-items > .link{
  font-size: 24px;
  /* border: 1px solid red; */
  text-align: left;
}
  .nav-items > .link::before {
    background: transparent;
  }

  .nav-items.open {
    transform: translateX(0);
  }

  .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }

  .bar::before,
  .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .bar::before {
    width: 32px;
    transform: translateY(-8px);
    right: 0;
  }

  .bar::after {
    right: 0;
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
  .contactButton {
    color: #ffffff;
    border: none;
    border-radius: 20px;
    padding: 10px 24px;
    font-size: 25px;
    cursor: pointer;
    background-image: linear-gradient(to right, #FFB04D, #FB686A);
  }
}

@media (min-width: 300px) and (max-width: 678px) {
  .Navbar {
    /* border: 1px solid red; */
    width: 100%;
    height: 70px;
    background: linear-gradient(to right, #356cfb, #b104c8);
  }
  .container {
    /* border: 1px solid blue; */
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-logo {
    margin: 15px 0 15px 15px; /* Adjust margin to push logo to the left */
  }
  .contactButton {
    width: 94%;
    margin-left: 3%;
    display: block; /* Set the button to take full width */
    /* margin: 0 20%; */
  }
  .buttonLink {
    color: #ffffff;
    text-decoration: none;
  }
  .Navbar {
    padding: 0;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, #356cfb, #b104c8);
  }
  .nav-items {
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    background: #356cfb;
    left: 0;
    width: 100%;
    height: 60%;
    transform: translateX(-100%);
    transition: all 0.45s;
    z-index: 9999;
    margin-top: 5px;
  }
.link{
  /* border: 1px solid red; */
  text-align: left;
}
  .nav-items > .link::before {
    background: transparent;
  }

  .nav-items.open {
    transform: translateX(0);
  }

  .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }

  .bar::before,
  .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .bar::before {
    width: 32px;
    transform: translateY(-8px);
    right: 0;
  }

  .bar::after {
    right: 0;
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }

}
